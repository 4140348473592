import Privileges from '../../../lib/constants/privileges.js';

export default [
    {
        heading: 'Administration',
        iconType: 'code',
        features: [
            {title: "Holidays", role: Privileges.SEARCH_HOLIDAYS, route: '/holidays'},
            { heading: 'Logs',
              iconType: 'dot-chart',
              features: [
                    {title: "Activity Logs", route: '/activity_logs', role: Privileges.SEARCH_APP_LOGS}
                ],
            },
            {title: "Remarks", role: Privileges.SEARCH_REMARKS, route: '/remarks'},
            {title: "Roles", role: Privileges.SEARCH_ROLES, route:'/roles'},
            {title: "Sent Emails", role: Privileges.SEARCH_SENT_EMAILS, route: '/sent_emails'},
            {title: "Task Types", role: Privileges.SEARCH_TASK_TYPES, route :'/task_types'},
            {title: "Tax Regions", role: Privileges.SEARCH_TAX_REGIONS, route: '/tax_regions'},
            {heading: 'Text Templates',
                iconType: 'file-text',
                features: [
                    {title: "Templates", role: Privileges.SEARCH_TEXT_TEMPLATES, route :'/text_templates'},
                    {title: "Default Quote Terms", role: Privileges.SEARCH_DEFAULT_QUOTE_TERMS, route :'/default_quote_terms'},
                ]
            },
            {title: "Users", role: Privileges.SEARCH_USERS, route: '/users'},
        ]
    },
    { 
        heading: 'Accounting',
        iconType: 'dollar',
        features: [
            {title: "Credit Holds", role: Privileges.SEARCH_CREDIT_HOLDS, route: '/credit_holds'},
            { heading: 'Distribute',
              iconType: 'dot-chart',
              features: [
                    {title: "Attachment Limits", route: '/attachment_limits', role: Privileges.SEARCH_ATTACHMENT_LIMITS},
                    {title: "Invoice Batches", route: '/invoice_batches', role: Privileges.SEARCH_INVOICE_BATCHES},
                    {title: "Invoice Recipients", role: Privileges.SEARCH_INVOICE_RECIPIENTS, route: '/invoice_recipients'},
                ],
            },
            {title: "Invoices", role: Privileges.SEARCH_INVOICES, route: '/invoices'},
            {title: "Payment Requests", role: Privileges.SEARCH_PAYMENT_REQUESTS, route: '/payment_requests'},
            {title: "Transactions", role: Privileges.SEARCH_TRANSACTIONS, route: '/transactions'},
            {title: "Invoice Payments", role: Privileges.SEARCH_INVOICE_PAYMENTS, route: '/invoice_payments'},
            {title: "Bad Debts", role: Privileges.SEARCH_BAD_DEBTS, route: '/bad_debts'},
        ]
    },
    {
        heading: 'Catalogs',
        iconType: 'database',
        features: [
            {title: "Parts", role: Privileges.SEARCH_PARTS, route: '/parts'},
            {title: "Services", role: Privileges.SEARCH_SERVICES, route: '/services'},
        ]
    },
    {
        heading: 'Customers',
        iconType: 'contacts',
        features: [
            {title: "Customers", role: Privileges.SEARCH_CUSTOMERS, route: '/customers'},
        ]
    },
    { 
        heading: 'Employees',
        iconType: 'team',
        features: [
            {title: "Expenses", role: Privileges.SEARCH_EMPLOYEE_EXPENSES, route: '/company/expenses'},
            {title: "Payouts", role: Privileges.SEARCH_EXPENSE_PAYOUTS, route: '/expense_payouts'},
            {title: "Projects", role: Privileges.SEARCH_EMPLOYEE_PROJECTS, route: '/company/projects'},
            {title: "Tasks", role: Privileges.SEARCH_EMPLOYEE_TASKS, route: '/global/tasks'},
            {title: "Time Off", route: '/employee_time_off', role: Privileges.SEARCH_EMPLOYEE_TIME_OFF},
            {title: "Time Off Calendar", role: Privileges.SEARCH_EMPLOYEE_TIME_OFF, route: '/employee_time_off/calendar'},
            {title: "Quotes", role: Privileges.SEARCH_EMPLOYEE_QUOTES, route: '/company/quotes'},
        ]
    },

    {
        heading: 'Expenses',
        iconType: 'credit-card',
        features: [
            {title: "Expenses", role: Privileges.SEARCH_EXPENSES, route: '/expenses'},
        ]
    },


    {
        heading: 'Inspections',
        iconType: 'schedule',
        features: [
            {title: "Calendar", role: Privileges.SEARCH_INSPECTIONS, route: '/inspections/schedule'},
            {title: "Inspections", role: Privileges.SEARCH_INSPECTIONS, route: '/inspections'},
            {title: "Inspection Cards", role: Privileges.SEARCH_INSPECTION_CARDS, route: '/inspection_cards'},
            {title: "Upcoming", role: Privileges.SEARCH_INSPECTIONS, route: '/inspections/upcoming'},
        ]
    },
    {
        heading: 'Inventory',
        iconType: 'appstore-o',
        features: [
            {title: "Counts", role: Privileges.SEARCH_INVENTORY, route: '/inventory'},
            {title: "Locations", role: Privileges.SEARCH_INVENTORY_LOCATIONS, route: '/inventory_locations'},
            {title: "Move Inventory", role: Privileges.SEARCH_INVENTORY, route: '/inventory_transfer'},
            {title: "Purchase Orders", role: Privileges.SEARCH_PURCHASE_ORDERS, route: '/company_purchase_orders'},
            {title: "Receiving Slips", role: Privileges.SEARCH_RECEIVING_SLIPS, route: '/receiving_slips'},
            {title: "Packing Slips", role: Privileges.SEARCH_PROJECT_PACKING_SLIPS, route: '/project_packing_slips'},
        ]
    },

    {
        heading: 'Phone Calls',
        iconType: 'phone',
        features: [
            {title: "Call History", role: Privileges.SEARCH_PHONE_LOGS, route:'/phone_logs'},
            {title: "Phone Stations", role: Privileges.SEARCH_PHONE_STATIONS, route:'/phone_stations'},
            {title: "Calls STT", role: Privileges.SEARCH_STT_CALLS, route:'/stt_calls'},
        ]
    },

    {
        heading: 'Projects',
        iconType: 'folder',
        features: [
            {title: 'Projects', role: Privileges.SEARCH_PROJECTS, route:'/projects'},
        ]
    },

    {
        heading: 'Reports',
        iconType: 'bar-chart',
        features: [
            {title: 'Company', route: '/dashboards/company', role: Privileges.REPORT_COMPANY},
            {title: 'Weekly Time Off', route: '/reports/current_week_time_off', role: Privileges.REPORT_WEEK_TIME_OFF},
            {title: 'Phone Logs', route: '/reports/phone_logs', role: Privileges.REPORT_PHONE_LOGS},
        ]
    },
    {
        heading: 'Sales',
        iconType: 'rocket',
        features: [
            {title: "Quotes", role: Privileges.CREATE_QUOTES, route: '/quotes'},
        ]
    },


    {
        heading: 'Sites',
        iconType: 'shop',
        features: [
            {title: "Sites", role: Privileges.SEARCH_SITES, route: '/sites'},
        ]
    },

    {
        heading: 'Tasks',
        iconType: 'flag',
        features: [
            {title: "Tasks", route: '/tasks'},
            {title: "Company Tasks", role: Privileges.SEARCH_EMPLOYEE_TASKS, route: '/company/tasks'},
            {title: "CAD Tasks", role: Privileges.SEARCH_CAD_TASKS, route: '/cad/tasks'},
            {title: "Inspection Tasks", role: Privileges.SEARCH_INSPECTION_TASKS, route: '/inspection/tasks'},
            {title: "Installation Tasks", role: Privileges.SEARCH_INSTALLATION_TASKS, route: '/installations/tasks'},
            {title: "KNOX Tasks", role: Privileges.SEARCH_KNOX_TASKS, route: '/knox/tasks'},
            {title: "Permitting Tasks", role: Privileges.SEARCH_PERMITTING_TASKS, route: '/permitting/tasks'},
        ]
    },

    {
        heading: 'Vendors',
        iconType: 'team',
        features: [
            {title: "Vendors", role: Privileges.SEARCH_VENDORS, route: '/vendors'},
        ]
    },

];

