import colors from 'lib/constants/resourceColors';
import Privileges from '../lib/constants/privileges';
import api from 'api';
import invoiceBadgeStyle from 'lib/helpers/invoiceBadgeStyle';

export default {
    defaults: {
        name: 'Invoice',
        color: colors.invoices,
        formFields: [
            {key:'invoice_number', static: true},
            {key:'payment_request_number', 
                static: true, 
                placeholder: 'None', 
                link: {url:'/payment_requests/:payment_request_id', privileges: Privileges.READ_PAYMENT_REQUESTS}},
            {label: 'PO Number', key:'po_number'},
            {key: 'project_description',
                readOnly: true,
                static: true,
                label: 'Project',
                link: {url:'/projects/:project_id', privileges: Privileges.READ_PROJECTS}},
            {key: 'customer_name',
                readOnly: true,
                static: true,
                label: 'Customer',
                link: {url:'/customers/:customer_id', privileges: Privileges.READ_CUSTOMERS}},
            {key: 'salesperson_name',
                readOnly: true,
                static: true,
                label: 'Salesperson',
                link: {url:'/users/:salesperson_id', privileges: Privileges.READ_USERS}},
            '/date(Date)',
            {key: 'tax_region',
                //showInCreateMode: false,
                type: 'options',
                defaultValue: 'miami-dade',
                options: [
                    {value: 'miami-dade', label: 'Miami Dade'},
                    {value: 'broward', label: 'Broward'},
                    {value: 'exempt', label: 'Tax Exempt'},
                    {value: 'notax', label: 'No Tax'},
                ],
            },
            {static: true, key: 'subtotal', type: 'currency'},
            {static: true, key: 'tax_total', label: 'Tax', type: 'currency'},
            {static: true, key: 'grand_total', label: 'Invoice Total', type: 'currency'},
            {static: true, key: 'total_paid', type: 'currency'},
            {static: true, key: 'total_bad_debt', type: 'currency'},
            {static: true, key: 'open_balance', type: 'currency'},
            {static: true, key: 'invoice_status', label: 'Status'},
            '_payment_term{COD|COD,DUE_ON_RECEIPT|Due On Receipt,NET30|Net 30,NET60|Net 60,NET90|Net 90,PROJECT|Project}',
            {key: 'parts',
                type: 'references',
                heading: 'Items',
                createLabel: 'Add Item',
                reloadPageOnCreate: true,
                dataTransform: 'items',
                dataUrl: `/invoices/:id?fields=items`,
                createUrl: (values) => { return `/invoices/${values.id}/items` },
                deleteUrl: (values) => { return `/invoices/${values.id}/items/:id` },
                editorActions: [ 
                    { label: 'Set Unit Prices to Zero', 
                       icon: 'minus-circle', 
                    method: async ({values, parameters, reload, loading, success, error}) => {
                            let invoice_id = values.id;

                            loading('Updating');
                            let result;
                            try {
                                result = await api.rpc('zeroInvoiceItemUnitPrices', {invoice_id})
                            } catch (e) {
                                throw new Error(e);
                            }
                            
                            if(result.success) {
                                success({
                                    title: 'Success', 
                                    content: 'Unit Prices have been cleared.',
                                    okText : 'Ok',
                                });
                            } else {
                                error({
                                    title: 'Error', 
                                    content: `Couldn't clear unit prices.`,
                                    okText : 'Ok',
                                });
                            }

                            reload(true);
                        }
                    }
                ],
                formTemplateSearch: {
                    displayKey: 'product_number',
                    placeholder: 'Search Product Number',
                    suggestFormat: ({product_number, description, unit_price}) => { return {
                        value: {
                            product_number,
                            description,
                            unit_price
                        },
                        text: product_number
                    } },
                    suggestUrl: '/products', 
                    suggestFields: ['id', 'product_number', 'product_number', 'description', 'unit_price'],
                    fieldMappings: {
                        'product_number': 'product_number',
                        'description': 'description',
                        'unit_price': 'price',
                    }
                },
                formFields: [
                    {key: 'product_number' },
                    {key: 'description', type: 'longtext'},
                    {key: 'price', 
                        type: 'currency', 
                        label: 'Unit Price',
                        inputRef: 'price',
                    },
                    {key: 'quantity', type: 'number'},
                    {key: 'taxable', type: 'boolean', defaultValue: true},
                ],
                tableProps: { 
                    rowURL: '/invoice_items/:id',
                    columns: [
                    {heading: 'Row', accessor:'position', type:'number', cellWidth: '10px'},
                    {accessor: 'product_number', cellWidth: '250px'},
                    {accessor: 'description'},
                    {accessor: 'price',  type:'currency', heading: 'Unit Price', cellWidth: '150px'},
                    {accessor:'quantity', type:'number', cellWidth: '50px'},
                    {accessor: 'taxable', type: 'boolean', cellWidth: '25px'},
                ] },
            },
            {key: 'payments',
                type: 'references',
                heading: 'Payments',
                createLabel: 'Add a Payment',
                createPrivileges: [Privileges.CREATE_INVOICE_PAYMENTS],
                createUrl: '/invoice_payments',
                readPrivileges: [Privileges.READ_INVOICE_PAYMENTS],
                reloadPageOnCreate: true,
                dataTransform: 'payments',
                dataUrl: `/invoices/:id?fields=payments`,
                // createUrl: (values) => { return `/invoice_payments` },
                deleteUrl: (values) => { return `/invoice_payments/:id` },
                injectedValues: (values) => { return {invoice_id: values.id }},
                formFields: [
                    {key: 'amount',
                        type: 'currency',
                        required: true,
                    },
                    {key: 'check_number'},
                    {label: 'Last 4 Credit Card Digits', key: 'last_digits'},
                    {key: 'memo'},
                    {key: 'file', type: 'file', label: 'Attachment', urlKey: 'source_file_url'},
                ],
                tableProps: {
                    rowURL: '/invoice_payments/:id',
                    columns: [
                    {accessor: 'created_at', type: 'date', cellWidth: '120px'},
                    {accessor: 'transaction_number', cellWidth: '150px'},
                    {accessor: 'amount', type: 'currency', cellWidth: '150px'},
                    {accessor: 'memo'},
                    {accessor: 'voided', type: 'boolean', cellWidth: '75px'},
                    {heading: 'Attachment', type: 'file', key: 'file_url', label: 'View Attachment'}
                ] },
            },


        ],
    },

    view: {
        formActions: [
            {   label: 'Print Invoice',
                icon: 'printer-o',
                method: async ({values, history, inputValues}) => {
                    console.log("Got values ", values, inputValues);
                    let pdfData = {
                        proforma: false,
                        po_number: values.po_number,
                        salesperson: values.salesperson_name,
                        project_description: values.project_description,
                        bill_name: values.customer_name,
                        bill_address: values.customer_address,
                        bill_address2: values.customer_address2,
                        bill_city: values.customer_city,
                        bill_state: values.customer_state,
                        bill_zipcode: values.customer_zipcode,
                        ship_name: values.customer_name,
                        ship_address: values.customer_address,
                        ship_address2: values.customer_address2,
                        ship_city: values.customer_city,
                        ship_state: values.customer_state,
                        ship_zipcode: values.customer_zipcode,
                        payment_term: values.payment_term,
                        property_name: values.site_name,
                        shorten_descriptions: values.shorten_descriptions,
                        show_unit_prices: false,
                        payment_request_items: values.items,
                        invoice_number: values.invoice_number,
                        amount_due: values.grand_total,
                        payments_made: values.total_paid,
                        balance_due: values.open_balance,
                        parts_price_subtotal: values.subtotal,
                        services_price_subtotal: 0,
                        tax_due: values.tax_total,
                        freight: 0,
                        created_at: values.created_at,
                        date: values.date
                    };
                        
                    const filename = 'payment_request'

                    let response;
                    try {
                        response = await api.create('/pdfs/payment_requests', {pdfData, filename});
                        console.log("Got response ", response);
                        window.open(response.body.url, '_blank');
                    } catch (e) {
                        console.log(`Got error ${e}`);
                        throw new Error(e);
                    }

                }
            },
        ]
    },

    search: {
        allowDateFilter: true,
        heading: 'Invoices',
        fields: ['id', 'invoice_number','description', 'grand_total','voided', 'date', 'customer_name', 'project_description', 'salesperson_name', 'salesperson_avatar', 'created_at','updated_at', 'open_balance', 'invoice_status'],
        dateFieldOptions: [{label: 'Invoice Date', value: 'date', description: 'invoiced'},
                           {label: 'Created', value: 'created_at', description: 'created'}],
        filterOptions: [
            {label: 'Project Customer Invoices', filters: { voided:false, customer_has_projects: true }},
            {label: 'Non-Project Customer Invoices', filters: { voided: false, customer_has_projects: false }},
             {label: 'All', filters: { voided: false }},
             {label: 'Void', filters: { voided: true }},
        ],
        links: [
            {downloadURL: (search_state) => { 
                let params = '';
                if(search_state.search_by_date) {
                    let queries = [];
                    if(search_state.start_date) {
                        queries.push(`start_date=${search_state.start_date}`);
                    }
                    if(search_state.end_date) {
                        queries.push(`end_date=${search_state.end_date}`);
                    }
                    if(search_state.selected_date_range_type) {
                        queries.push(`date_range_type=${search_state.selected_date_range_type}`);
                    }
                    params = queries ? `?${queries.join('&')}` : ''
                }

                return `/files/invoice_received_payments${params}`;
            },
                title: 'Download All Received Payments',
                requiredPrivileges: [Privileges.FILE_READ_INVOICE_RECEIVED_PAYMENTS]
            },
            {downloadURL: (search_state) => {
                let params = '';
                if(search_state.search_by_date) {
                    let queries = [];
                    if(search_state.selected_date_range_type) {
                        queries.push(`date_range_type=${search_state.selected_date_range_type}`);
                    }
                    params = queries ? `?${queries.join('&')}` : ''
                }

                return `/files/sales_tax_report${params}`;
            },
                title: 'Download Sales Tax Report',
                requiredPrivileges: [Privileges.FILE_READ_SALES_TAX_REPORT]
            },
            {downloadURL: `/files/sales_tax_error_report`,
                title: 'Download Sales Tax Error Report',
                requiredPrivileges: [Privileges.FILE_READ_SALES_TAX_ERROR_REPORT]
            },
        ],
        searchBy: ['invoice number', 'project name', 'customer name', 'grand total'],
        tableProps: {
            rowURL: '/invoices/:id',
            columns: [
                {heading: 'Created', cellWidth: '100px', dateFormat: 'MMM DD, YYYY', type:'date', accessor: 'created_at'},
                {heading: 'Invoice #', accessor: 'invoice_number', cellWidth: '110px', style: 'bold'},
                {heading: 'Project', accessor: 'project_description'},
                {heading: 'Customer', accessor: 'customer_name'},
                {type: 'currency', heading: 'Open Balance', accessor: 'open_balance'},
                {type: 'currency', accessor: 'grand_total', heading: 'Invoice Total'},
                {heading: 'Salesperson', accessor: 'salesperson_name'},
                {heading: 'Status', type: 'badge', accessor: 'invoice_status', badgePropsFunc: invoiceBadgeStyle},
            ]
        }
    }
}
