
import colors from 'lib/constants/resourceColors';
import Privileges from 'lib/constants/privileges';

const form = [
    {key: 'date', static: true},
    {key: 'dir', static: true},
    {key: 'dtmf', static: true},
    {key: 'cid', static: true},
    {key: 'label', static: true},
    {key: 'score', static: true},
    {key: 'summary', static: true},
    {key: 'transcript', static: true},
]

export default {
    defaults: {
        name: 'STT Call',
        color: colors.phoneLogs,
        actions: ['search', 'view'],
        formFields: form,
    },

    view: {
        title: (s) => `${s.date} (${s.cid})`,
        formProps: {
            editPrivileges: [Privileges.UPDATE_STT_CALLS],
            deletePrivileges: [Privileges.DELETE_STT_CALLS],
        }
    },

    search: {
        heading: 'STT Calls',
        allowDateFilter: true,
        dateFieldOptions: [{label: 'Call Date', value: 'date', description: 'called'},
                           {label: 'Uploaded', value: 'created_at', description: 'uploaded'}],
        fields: ['id','date','dir','dtmf','cid', 'label', 'score', 'summary'],
        searchBy: ['transcript', 'summary', 'cid', 'dtmf'],
        tableProps: {
            rowURL: '/stt_calls/:id',
            columns: [
                {accessor: 'date', type: 'date', dateFormat: 'ddd MMM D, YYYY hh:mm A', cellWidth:'140px'},
                {accessor: 'label', cellWidth: '60px'},
                {accessor: 'score', cellWidth: '40px'},
                {accessor: 'dir', cellWidth: '25px'},
                {accessor: 'dtmf', cellWidth: '50px'},
                {accessor: 'cid', cellWidth: '80px'},
                {accessor: 'summary'},
            ]
        }
    }
}
