import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

function WorkorderResolution() {
    const [data, setData] = useState([]);

    const fields = ['first_name', 'last_name', 'email', 'description', 'hours', 'start_date', 'end_date', 'type'];

    const renderWorkorderResolution = () =>  {
        return  <span>Resolution</span>
    }

    return <div className='report'>{renderWorkorderResolution()}</div>
}

export default withRouter(WorkorderResolution);
